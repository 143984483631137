import 'bootstrap';
import './sass/main.scss';

// import 'vr-bg-video' from '../assets/vr-bg-video.mp4';

const appHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty('--app-height', `${
        window.innerHeight
    }px`);
};

let prevScrollpos = window.pageYOffset;
let navbar = document.getElementById('navbar');
let navButton = document.getElementById('nav-hamburger');
let ar = document.getElementById('ar');
let vr = document.getElementById('vr');
let mainLogo = document.getElementById('main-logo');
let vrAr = document.getElementById('vr-ar');
let xraybtn = document.getElementById("xraybutton");
let mainHero = document.getElementById("main-hero");

let navCollapsed = false;

$('.navbar-collapse').on('show.bs.collapse', function () {
    navCollapsed = true;
    mainHero ? mainHero.style.display = 'none' : null
}).on('hide.bs.collapse', function () {
    $(this).removeClass("show");
    navCollapsed = false;
    mainHero ? mainHero.style.display = 'block' : null
})

window.addEventListener('load', onVrViewLoad);
window.addEventListener('load', onVrFunkyLoad);

function onVrViewLoad() {
    let vrView = new VRView.Player('#vrview', {
      image: 'http://beta.vhsoft.io/vr-360.png',
      width: '100%',
      height: 400
    });
}
function onVrFunkyLoad() {
    let vrView = new VRView.Player('#vrfunky', {
        image: 'http://vhsoft.io/vr-360-funky4.png',
        width: '100%',
        height: 400
      });
}

window.onscroll = function () {
    var currentScrollPos = window.pageYOffset;
    if (mainLogo) {
        if (currentScrollPos > '30') {
            mainLogo.style.display = 'none';
        } else {
            mainLogo.style.display = 'block';
        }
    }
    if (prevScrollpos > currentScrollPos || navCollapsed === true) {
        navbar.style.top = '0';
    } else {
        navbar.style.top = '-100px';
    } prevScrollpos = currentScrollPos;
};

if (vrAr) {
    vrAr.addEventListener('click', function () {
        if (document.querySelector('input[name = "vr-ar"]:checked')) {
            ar.style.display = 'block';
            vr.style.display = 'none';
        } else {
            vr.style.display = 'block';
            ar.style.display = 'none';
        }
    });
}

navButton.addEventListener('click', function () {
    navbar.style.top = '0';
});

window.addEventListener('resize', appHeight);
appHeight();

const validateForm = () => {
    let form = document.getElementById('contact-form');
    form.classList.add('was-validated');
    return form.checkValidity();
};

$(function () {
    window.verifyRecaptchaCallback = function (response) {
        $('input[data-recaptcha]').val(response).trigger('change')
    }

    window.expiredRecaptchaCallback = function () {
        $('input[data-recaptcha]').val("").trigger('change')
    }


    $('#contact-form').on('submit', function (e) {
        console.log("SUBMIT FORM")
        e.preventDefault();
        e.stopPropagation();

        if (validateForm()) {
            console.log("FORM VALIDATED")
            var url = 'contact.php';

            $.ajax({
                type: 'POST',
                url: url,
                data: $(this).serialize(),
                success: function (data) {
                    let form = document.getElementById('contact-form');
                    form.classList.remove('was-validated');

                    var messageAlert = 'alert-' + data.type;
                    var messageText = data.message;


                    var alertBox = '<div class="alert ' + messageAlert + ' alert-dismissable"><button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>' + messageText + '</div>';

                    if (messageAlert && messageText) {
                        $('#contact-form').find('.messages').html(alertBox);
                        grecaptcha.reset();
                        if (data.type === 'success') {
                            $('#contact-form')[0].reset();
                        }
                    }
                }
            });
            return false;
        }
    });
});

if (xraybtn) {
    xraybtn.addEventListener("click", xray);
}

function xray() {
    let xr = document.getElementById("xray");

    if (xr.src == "http://beta.vhsoft.io/b113243f414657f3e0cb.png") {

        xr.src = "http://beta.vhsoft.io/9e244ee7e6b758a72b0a.png";
    } else {
        xr.src = "http://beta.vhsoft.io/b113243f414657f3e0cb.png";
    }
}
